import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  regions: [],
  services: [],
  serviceTier: '',
  stps: [],
  types: [],
  bulkDiscount: false,
  filtersSelected: false,
  postcode: '',
  distance: '',
  searchTerm: '',
};

const sliceName = 'searchCriteria';

// Search Criteria Slice
const searchCriteriaSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // Clear all
    clearSearchCriteria(state) {
      state.regions = [];
      state.services = [];
      state.serviceTier = '';
      state.bulkDiscount = false;
      state.filtersSelected = false;
      state.stps = [];
      state.postcode = '';
      state.distance = '';
      state.types = [];
      state.searchTerm = '';
    },
    // Set selected filters
    setSearchCriteriaSelected(state, { payload }) {
      state.filtersSelected = payload;
    },
    // Toggle the bulk discount filter
    toggleBulkDiscount(state, { payload }) {
      state.bulkDiscount = payload;
    },
    // Add a type from the state selected list
    toggleTypeOn(state, { payload }) {
      if (state.types.length === 0) {
        state.types.push(payload);
      }
    },
    selectTypes(state, { payload }) {
      state.types = payload;
    },
    // Add a region from the state selected list
    toggleRegionOn(state, { payload }) {
      if (!state.regions.includes(payload)) {
        state.regions.push(payload);
      }
    },
    selectRegions(state, { payload }) {
      state.regions = payload;
    },
    // Add an STP from the state selected list
    toggleStpOn(state, { payload }) {
      if (!state.stps.includes(payload)) {
        state.stps.push(payload);
      }
    },
    selectStps(state, { payload }) {
      state.stps = payload;
    },
    // Add or remove a service from the state selected list
    toggleService(state, { payload }) {
      if (!state.services.includes(payload)) {
        state.services.push(payload);
      }
    },
    // Add specific services
    selectServices(state, { payload }) {
      state.services = payload;
    },
    // Specify service type
    selectServiceTier(state, { payload }) {
      state.serviceTier = payload;
    },
    // Add a postcode
    selectPostcode(state, { payload }) {
      state.postcode = payload;
    },
    // Add a distance
    selectDistance(state, { payload }) {
      state.distance = payload;
    },
    // Add a search term
    selectSearchTerm(state, { payload }) {
      state.searchTerm = payload;
    },
  },
});

export default searchCriteriaSlice.reducer;
export const {
  clearSearchCriteria,
  toggleTypeOn,
  selectTypes,
  toggleRegionOn,
  selectRegions,
  toggleStpOn,
  selectStps,
  toggleService,
  selectServices,
  selectServiceTier,
  setSearchCriteriaSelected,
  toggleBulkDiscount,
  selectDistance,
  selectPostcode,
  selectSearchTerm,
} = searchCriteriaSlice.actions;
export const searchCriteriaSelector = (state) => state[sliceName];
